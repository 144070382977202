* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px;
  font-size: 0.9rem;
  width: "45px";
  height: "45px";
  background: #ccc;
  cursor: pointer;
  border-radius: 10px;
}

.back-to-top:hover {
  background: red;
}

